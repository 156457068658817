import React from 'react';

import Slider from './Slider';
import Banner from './Banner';
import Gustos from './Gustos';

const API_KEY = 'AIzaSyC8k_kVHlcuwWSiJjA_CxKAEPDfoY42SF0'

export default (props) => {    
    let prop = document.body.clientWidth > 600 ? 'pictures' : 'pictures_mobile';
    let pictures = (props[prop] && props[prop].length && document.body.clientWidth < 600) ? props[prop] : props['pictures'];
    if (props.gustos) {
        return <Gustos {...props} />
    }
    
    if (props.isTitle) {
        return <Banner {...props} image={pictures && pictures[0].src} />
    }
    if (props.banner || props.isCarousel) {
        return <Slider onClick={props.onClickSection} height={'50vh'} images={pictures} type="contain" settings={{slidesToShow: props.PicsPerPage || 1,slidesToScroll: 1, dots: false,  autoplaySpeed: props.speed || 7000, }} />
    }
    return (
        <a key={props.key} onClick={props.onClickSection} className={props.classNameContent} style={{flexDirection: props.horizontal? props.left? 'row-reverse' : 'row' : props.imageUp ? 'column-reverse': 'column', alignItems: props.center ? 'center' : 'flex-start', justifyContent: props.product ? 'center' : 'flex-start', padding: '0 10vw'}}>
            <div className="content-content" style={{ textAlign: props.center ? 'center' : 'left', width: !props.widthFull ? '70%' : '100%', marginBottom: 45, padding: props.horizontal ? 0 : 0 }}>
                {props.title !== '' && (!props.product && !props.sucursal) && <h2>{props.title}</h2>}
                {props.description && (!props.product && !props.sucursal) && <div style={{margin: '10px auto'}} dangerouslySetInnerHTML={{__html: props.description}}/>}
                {props.html !== '' && <div style={{margin: '10px auto'}} dangerouslySetInnerHTML={{__html: props.html}}/>}
            </div>
            <div className="responsive" style={{display:'flex', width: props.fullWidth ? '100%' : 'auto', padding: '10px 10vw'}}>
                {pictures && pictures.length == 1 && <img className={`img-round ${props.horizontal ? 'img-row' : ''} ${props.product? 'image-full' :'product-detail'}`} alt={props.title} src={pictures[0].src} />}
                {
                    pictures && pictures.length > 1 ? <Slider width="40%" type={'contain'} height={'40vh'} images={pictures} settings={{slidesToShow: props.PicsPerPage || 1,slidesToScroll: props.slidesToScroll || 1, autoplaySpeed: 6000, dots: true, ...props.settings}} /> : null
                }
                {props.horizontal? null :<div style={{width: 40}}></div>}
                {props.product && <div style={{margin: '10px auto', flex: 2, padding: 24}} >
                    <h2>{props.title}</h2>
                    {props.product && <div style={{margin: '10px auto', flex: 2}} dangerouslySetInnerHTML={{__html: props.description}}/>}
                </div>}

                {props.sucursal && <div style={{margin: '10px auto', flex: 2, padding: 24}} >
                    <h2>{props.title}</h2>
                    {props.sucursal && <div style={{margin: '10px auto', flex: 2}} dangerouslySetInnerHTML={{__html: props.description}}/>}
                </div>}

            </div>
            {
                props.map && (
                    <div><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${props.map}`}></iframe></div>
                )
            }
        </a>
    )
}