import React, { Component, useEffect, useState } from 'react';
import {connect} from 'react-redux';
import Icon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import Slider from '../../components/Slider';
import Content from '../../components/Content';
import {saveForm, getProduct, getPoductCategories, getSursales, getPromotions, getPromociones} from '../../redux/Actions'
import { useHistory, useParams } from 'react-router';
import {useSelector, useDispatch} from 'react-redux'
const section = "Home"
const DetailsView = (props) => {
    const {goBack} = useHistory()
    const [product, setProduct] = useState({})
    const [sucursal, setSucursal] = useState({})
    const [promocion, setPromocion] = useState({})
    const params = useParams();
    useEffect(() => {
        getData()
    }, [])
    
    const getData = async () => {
        let name = params ? params.name : null
        if(props.sucursales) {
            let sucursales = await getSursales(props.instance_id)
            let da = sucursales.filter((i)=> i.title.replace(' ','_') == name)[0];
            if (da) setSucursal(da);
        }
        if(props.promociones) {
            let promociones = await getPromociones()
            console.log(promociones, name)
            let da = promociones.filter((i)=> i.title !== name.replace('_',' '))[0];
            console.log(da)
            if (da) setPromocion(da);
        }
        else {
            let categories = await getPoductCategories(props.instance_id)
            if (name) {
                console.log(name)
                let cat = categories.filter(a=> a.title == name.split('-')[0].replace(/_/g, " "))[0].id
                setProduct(await getProduct(props.instance_id, name.split('-')[1].replace(/_/g, " "),cat))
            }
        }
    } 
    console.log("ASD", promocion)
    return (
        <div className="container">
            <IconButton className="animated zoomIn" onClick={()=> goBack()} style={{position: 'absolute', top: 120, right: '10vw', zIndex: '99999'}}>
                <Icon fontSize="large" />
            </IconButton>
            {product && <Content horizontal product {...product} />}
            {sucursal && <Content sucursal {...sucursal} />}
            {promocion && <Content product {...promocion} />}
        </div>    
    )
}


export default DetailsView
