import React from 'react';
import ReactTypingEffect from 'react-typing-effect';

const Group = (props) => {
    console.log("GOURP", props)
    return(
        <a onClick={()=> props.onClick ? props.onClick() : () => {}} key={props.key} className="" style={{...styles.groupBox, backgroundImage: `url(${props.image})`}}>
            {(props.title && props.typing && !props.hideTitle) && <ReactTypingEffect className={"banner-title"} text={props.title.toUpperCase()}/>}
            {(props.title && !props.typing && !props.hideTitle) &&<h3 className="banner-title">{props.title.toUpperCase()}</h3>}
            {props.children}
        </a>
    )
}

const styles = {
    groupBox : {
        display: 'flex',
        flexDirection: 'column',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        alignItems: 'center',
        justifyContent: 'center',
        height: '250px', 
        width: '100%',
        marginBottom: 45
    }
}

export default Group;
