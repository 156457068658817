import React, {useState} from 'react';
import { Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Menu, { SubMenu, MenuItem, Divider } from 'rc-menu';
import scrollToComponent from 'react-scroll-to-component';
import {useLocation} from "react-router"

import 'rc-menu/assets/index.css';

const effect = 'pulse'

const styles = {
    contain: {
        display: 'flex',
        minHeight: 75, 
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: '--primary-color',
        boxShadow: '-1px 2px 5px 3px rgba(0,0,0,.1)',
        position: 'fixed',
        top: 0,
        zIndex: 999999, 
    },
    groupBox : {
        flex: 1,
        height: 45,
        maxWidth: '90vw',
        display: 'flex',
        justifyContent: 'space-between',
        // borderTop: '1px solid #333',
        // borderBottom: '1px solid #333',
    },
    img: {
        width: 'auto',
        position: 'relative',
        top: -65
    },
    mobile: (active) => ({
        color: !active ? '--primary-text-color' : '--secondary-text-color',
        fontSize: '1rem',
        width: '100%',
        height: 54,
        display: 'flex', 
        justifyContent: 'center',
        margin: '10px 0',
        background: active ? '--primary-color' : 'transparent',
        alignItems: 'center'
    }),
    btn : (active) =>({
        // flex: 1, 
        color: !active ? '--primary-text-color' : '--secondary-text-color',
        fontSize: '1rem',
        margin: '0px 10px',
        alignItems: 'flex-end',
        textAlign: 'center',
        background: 'transparent'
    })
}

export default (props) => {
    if(props.hide) return null
    const [open,setOpen] = useState(false)
    const [hover,setHover] = useState(false)
    const [anchor,setAnchor] = useState('anchor')
    const {pathname} = useLocation()
    const route = pathname;
    let nav = Object.keys(props.page_content).map(key => props.page_content[key])    
    let sections = nav.length ? nav.sort((a, b ) => a.order - b.order) : []    
    if(!sections.length) return null
    return(
        <div style={styles.contain} className="navbar" >
            <div key={props.key} className="nav-desktop" style={styles.groupBox}>
                <a onClick={()=> props.onClick(`/`)} style={{flex: 1, display: 'flex', alignItems: 'center' }} >
                    <img style={{width: 'auto', height: 75}}  src={props.navImage} />
                </a>
                <div style={{flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                {sections.sort((a, b) => a.order - b.order).map((item) => {
                    if (item.hide || item.route === 'home') return null
                    if (!item ? null : item['sub-categories'] && item['sub-categories'].length > 0){
                        return (
                                <Menu
                                    key={item.title}
                                    className="menu-custom"
                                    mode="horizontal"
                                    selectable={false}
                                    title="" haspopup={'false'}
                                   
                                    onClick={(e)=> {
                                        props.onClick(`/${item.route}/${e.key}`, false);
                                        document.getElementById(e.key) && document.getElementById(e.key).scrollIntoView({  behavior: 'smooth', block: 'center' })
                                        // props.onClick(`/${item.route}/${e.key}`, true)
                                    }}
                                    onSelect={(e) => {
                                        console.log(e)
                                        props.onClick(`/${item.route}/${e.key}`, true);
                                    }}>
                                    <SubMenu getPopupContainer={() => null} overflowedIndicator="" haspopup="false" popupClassName="hides" popupOffset={[0, 15]} popupClassName="menu-custom-ul" mode="horizontal" title={item.title.toUpperCase()} key="4" >
                
                                        {item['sub-categories'].sort((a,b) => a.order - b.order).map((i) => { 
                                            return (
                                                <SubMenu title="" haspopup={'false'} popupClassName="hides" onTitleClick={(e)=> props.onClick(`/${item.route}?category=${e.key}`)} key={i.name.replace(/ /g, "-")} title={i.name}>
                                                {props.products && props.products.filter(m => m.category && m.category.title ==  i.name).sort((a,b) => a.order - b.order).map((m) => (
                                                        <MenuItem title="" haspopup={'false'} key={`${i.name.replace(/ /g,"_")}-${m.title.replace(/ /g,"_")}`}>{m.title}</MenuItem>
                                                ))}
                                                </SubMenu>)
                                        })}
                                    </SubMenu>
                                </Menu>
                            )
                    }
                    return  (
                        <Button 
                            onMouseOver={item.route+"-simple-menu"} 
                            aria-haspopup="false"  
                            key={item.route}
                            onMouseOver={(e)=> {setHover(item['sub-categories'] && item['sub-categories'].length>0? true : false); setAnchor(e.currentTarget)}} 
                            className={`animated ${route === `/${item.route || ''}` && effect} nav-label`} 
                            onClick={()=> props.onClick(`/${item.route || ''}`)} >{item.title}
                        </Button>
                        )
                    }
                )}
                {props.instance.facebook_url && <a href={props.instance.facebook_url} target="_blank" className="nav-social" style={{marginLeft: 50, marginRight: 20}}>
                    <img height="25" src={require('../assets/icons/facebook.png')} />
                </a>}
                {props.instance.instagram_url && <a href={props.instance.instagram_url} target="_blank" className="nav-social">
                    <img height="25" src={require('../assets/icons/instagram.png')} />
                </a>}
                </div>
            </div>
            <div key={props.key} className="nav-mobile">
                {open ? <CloseIcon onClick={()=> setOpen(!open)} fontSize="large" /> : <MenuIcon onClick={()=> setOpen(!open)} fontSize="large" />}
                <a onClick={()=> {props.onClick('/');setOpen(false)}}><img style={{position: 'absolute', top: 30, left: 30, height: 40}}  src={props.navImage} /></a>
            </div>
            <div key={props.key} className={`nav-mobile-full ${open ? 'menu-open' : ''} ${!open ? 'menu-close' : ''}`}>
                {sections.sort((a, b) => a.order < b.order).map((item) => (
                    <a className={`hover-white`} key={item.route} onClick={()=> {props.onClick(`/${item.route || ''}`);setOpen(false)}} style={styles.mobile(route === item.route)}>{item.title}</a>
                ))}
            </div>
        </div>
    )
}
