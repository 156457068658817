import React from 'react';
import Typography from '@material-ui/core/Typography'

const Footer = (props) => {
    if(!props || !props.instance) return null
    return(
        <div style={{boxShadow: '0px -6px 7px 2px rgba(0,0,0,0.05)'}} className="footer">
        <div key={props.key} style={{...styles.InputBox, ...props.styles}} className="responsive">
            <div style={{flex: 1}}>
                <img height="150" src={props.instance && props.instance.pictures[1].src} />
            </div>
            <div style={{flex: 1, flexDirection: 'column',}}>
                <h4 style={{fontSize: '1.4rem'}}>Contacto</h4>            
                <ul style={{flex: 1, display: 'flex', alignItems: 'flex-start', flexDirection: 'column', listStyle: 'none', padding: 0}}>
                    <li style={styles.li}><Typography>{props.instance && props.instance.email}</Typography></li>
                    <li style={styles.li}><Typography>{props.instance && props.instance.phone}</Typography></li>
                    <li style={styles.li}><Typography>{props.instance && props.instance.address}</Typography></li>
                </ul>
            </div>
            <div style={{flex: 1, justifyContent: 'flex-end', flexDirection: 'column', listStyle: 'none', padding: 0}}>
                <h4 style={{fontSize: '1.4rem', marginBottom:10}}>{props.instance && props.instance.footertitle || 'Nosotros'}</h4>
                <div dangerouslySetInnerHTML={{__html: props.instance && props.instance.footer}}></div>
            </div>
        </div>
        </div>
    )
}

const styles = {
    InputBox : {
        flex: 1,
        display: 'flex',
        width: '90%',
        margin: 'auto',
        maxWidth: 1200, 
        padding: '25px 0',
        // alignItems:'center',
        justifyContent: 'space-between',
        marginBottom: 50,
        marginTop: 100
    },
    input: {
        color: '#fff', 
        background: 'transparent',
        border: 'none',
        borderBottom: '1px solid #333',
        width: '50%',
        height: 28,
        padding: 2,
        fontSize: 14
    }, 
    li: {
        marginBottom: 5,
        fontSize: '1rem'
    }
}

export default Footer;
