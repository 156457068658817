import React, {useState} from 'react';


import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Grid } from '@material-ui/core';


const inputStyle = {
  color: '#fff'
}

const modals = {
  "contact" : {
    title: "Contacto",
    fields: ['name', 'email', 'question']
  },
  "servicios" : {
    title: "Solicita nuestros servicios",
    text: 'Para consultas sobre catering u horeca complete el siguiente formulario. Le responderemos a la brevedad.',
    fields: ['name', 'email', 'services', 'message', 'tel']
  },
  "franquicias" : {
    title: "Mas Información de Franquicias",
    text: 'Si estás interesada/o en conocer más acerca de nuestro modelo de Franquicia, deberás enviar el formulario de solicitud que se presenta a continuación y posteriormente te enviaremos una respuesta, en su caso podremos hacer una presentación detallada del negocio y despegar todas tus inquietudes.',
    fields: ['name', 'email', 'tel', 'message']
  },
}

const initState = { name: '', services: '', message: '', tel: '', email: '', text: '', referido: '', instagram: '@', oferts: false, ofertsList: '', patente: '', dni: '' }

export default function FormDialog(props) {
  const [userInfo, setUserInfo] = useState(initState);
  if(!props.type) return null
  let modal = modals[props.type]
  return (
    <div style={{paddingTop: 50}}>
    <Grid style={{display: 'flex', flexDirection: 'column', minWidth: 400, width: '40vw', maxWidth: 600, margin: 'auto', color: '#333', borderRadius: 5, padding: 25, boxShadow: '1px 1px 5px 3px rgba(0,0,0,0)'}}>
        <h2 style={{ textAlign: 'center' }}>{modal.title}</h2>
        <p style={{ textAlign: 'center', marginBottom: 40, }}>{modal.text}</p>
        {modal.fields.indexOf('services') > -1 &&(
          <FormControl >
            <InputLabel htmlFor="offerts-input">¿Sobre que es su consulta? *</InputLabel>
            <Select
              native
              required
              value={userInfo.services}
              onChange={(e) => setUserInfo({...userInfo, services: e.target.value})}
              inputProps={{
                name: 'age',
                id: 'offerts-input',
              }}
            >
              <option value="" />
              <option value={true}>Horeca</option>
              <option value={false}>Catering</option>
            </Select>
          </FormControl>
        )}
          {modal.fields.indexOf('name') > -1 &&(
            <TextField
              margin="dense"
              id="name"
              value={userInfo.name}
              onChange={(e) => setUserInfo({...userInfo, name: e.target.value})}
              label="Nombre completo"
              type="text"
              style={inputStyle}
            />
          )}
          {modal.fields.indexOf('email') > -1 &&(
          <TextField
            margin="dense"
            id="name"
            value={userInfo.email}
            onChange={(e) => setUserInfo({...userInfo, email: e.target.value})}
            label="Email"
            type="email"
            style={inputStyle}
          />
          )}
          {modal.fields.indexOf('tel') > -1 &&(
            <TextField
              margin="dense"
              id="name"
              value={userInfo.tel}
              onChange={(e) => setUserInfo({...userInfo, tel: e.target.value})}
              label="Telefono"
              type="text"
              style={inputStyle}
            />
            )}
          {modal.fields.indexOf('message') > -1 &&(
          <TextField
            margin="dense"
            id="name"
            value={userInfo.message}
            onChange={(e) => setUserInfo({...userInfo, text: e.target.value})}
            label="Mensaje"
            type="text"
            multiline
            rows={4}
            rowsMax={10}
            style={inputStyle}
          />
          )}
          {modal.fields.indexOf('patente') > -1 &&(
          <TextField
            margin="dense"
            id="name"
            value={userInfo.patentes}
            onChange={(e) => setUserInfo({...userInfo, patente: e.target.value})}
            label="Patente"
            type="text"
            style={inputStyle}
          />
          )}
          {modal.fields.indexOf('dni') > -1 &&(
          <TextField
            margin="dense"
            id="name"
            value={userInfo.dni}
            onChange={(e) => setUserInfo({...userInfo, dni: e.target.value})}
            label="DNI"
            type="text"
            style={inputStyle}
          />
          )}
          {modal.fields.indexOf('instagram') > -1 &&(
          <TextField
            margin="dense"
            id="name"
            value={userInfo.instagram}
            onChange={(e) => setUserInfo({...userInfo, instagram: e.target.value})}
            label="Instagram"
            type="text"
            style={inputStyle}
          />
          )}
          {modal.fields.indexOf('oferts') > -1 && userInfo.oferts && (
            <TextField
              margin="dense"
              id="name"
              value={userInfo.ofertsList}
              onChange={(e) => setUserInfo({...userInfo, ofertsList: e.target.value})}
              label="¿Cuales?"
              type="text"
              style={inputStyle}
            />
          )}
          {modal.fields.indexOf('referido') > -1 &&(
          <TextField
            margin="dense"
            id="name"
            value={userInfo.referido}
            onChange={(e) => setUserInfo({...userInfo, referido: e.target.value})}
            label="¿Comó nos conociste?"
            type="text"
            style={inputStyle}
          />
          )}
          <Button style={{marginTop: 25, background: '#d61056', color: '#FFF'}} onClick={() => {props.onSend({ type: props.type, ...userInfo  }); setUserInfo(initState); alert("El mensaje ha sido enviado. muchas gracias") }} color="primary">
            Enviar
            </Button>
        </Grid>
    </div>
  );
}