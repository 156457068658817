import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { getGustos } from '../redux/Actions';

const GustoSlider = (props) => {
    useEffect(() => setGustos(props.gustosList), []);
    const [gustos, setGustos] = useState([]);
    return (
        <div className="gustos-slider"> 
            <h2>{props.title || 'Sabores de Helados'}</h2>
            <div className="gustos-grid">
            {gustos && gustos.sort((a,b) => a.order - b.order).map((g) => {
                return (
                    <div className="gustos-box">
                        <h3>{g.title}</h3>
                        <h4>{g.subtitle}</h4>
                        <ul className="gustos-list">
                            {g.list && g.list.map((sabor, key) => sabor && (
                                <li key={sabor.name + key}>{sabor.name}</li>
                            ))}
                        </ul>
                    </div>
                )
            })}
            </div>
        </div>
    );
}

export default GustoSlider