import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Route,  } from  'react-router';
import GridList from '@material-ui/core/GridList'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

import Slider from '../../components/Slider';
import Content from '../../components/Content';
import ContactForm from '../../components/ContactForm';
import ProductCard from '../../components/ProductCard';
import Filter from '../../components/Filter';
import Gustos from '../../components/Gustos';
import queryString from 'query-string';

import Detailsview from './detailsView';
import {getGustos, saveForm} from '../../redux/Actions'
const section = "Home"

class Home extends Component {
    state = {
        currentRoute : '/',
        category: null,
        product: null,
        gustos: []
    }
    async componentDidMount(){
        // const parsed = queryString.parse(this.props.location.search);
        // if(parsed.product) {
        //     setTimeout(() => {
        //         document.getElementById(parsed.type) && document.getElementById(parsed.type).scrollIntoView({ behavior: 'smooth', block: 'center' })
        //     }, 800);
        // }
        // if(parsed.category) {
        //     setTimeout(() => {
        //         document.getElementById(parsed.category) && document.getElementById(parsed.category).scrollIntoView({ behavior: 'smooth',  })
        //     }, 800);
        // }

        let gustos = await getGustos();
        console.log('====================================');
        console.log(gustos);
        console.log('====================================');
        this.setState({gustos})
    }

    handleProduct(item,) {
        window.scrollTo(0,0)
        this.props.dispatch({ type: "SET_PRODUCT", payload: item });
        this.props.history.push('/productos/' + `${item.category.title.replace(/ /g, '_')}-${item.title.replace(/ /g, '_')}`)
    }

    handleSucursal(item,) {
        window.scrollTo(0,0)
        this.props.dispatch({ type: "SET_SUCURSAL", payload: item });
        this.props.history.push('/sucursales/' + `${item.title.replace(/ /g, '_')}`)
    }

    handlePromocion(item,) {
        window.scrollTo(0,0)
        this.props.dispatch({ type: "SET_PROMOTION", payload: item });
        this.props.history.push('/nuestras-promociones/' + `${item.title.replace(/ /g, '_')}`)
    }
    
    render(){
        let content = this.props.page_content[this.props.history.location.pathname.replace(/\//g, '') == '' ? 'home' : this.props.history.location.pathname.replace(/\//g, '')]
        let prop = document.body.clientWidth > 600 ? 'pictures' : 'pictures_mobile';
        return (
            <div className="container">

                <Route exact path={`${this.props.history.location.pathname}`} component={ ()=> (
                    <div>
                    {content && content.sections && content.sections.sort((a, b ) => a.order - b.order).map(item => item && (
                        <Content 
                            key={item.title}
                            onClickSection={() => item.action && this.props.history.push(`${this.props.history.location.pathname}${item.action}`)} 
                            {...item} 
                            gustosList={this.state.gustos}
                            settings={item.sliderSettings}
                            classNameContent={(item.fullwidth || item.horizontal) ? 'responsive contentFull' : 'content'} 
                        />
                        ))}

                    {this.props.history.location.pathname === '/contacto' && (

                        <ContactForm 
                            fullwidth={false} 
                            type={'contact'}
                            onSend={(data) => this.props.dispatch(saveForm({...data, instance: this.props.instance.id})) }
                        />
                    )}
                    {this.props.history.location.pathname === '/servicios' && (

                        <ContactForm 
                            fullwidth={false} 
                            type={'servicios'}
                            onSend={(data) => this.props.dispatch(saveForm({...data, instance: this.props.instance.id})) }
                        />
                    )}
                    {this.props.history.location.pathname === '/franquicias' && (

                        <ContactForm 
                            fullwidth={false} 
                            type={'franquicias'}
                            onSend={(data) => this.props.dispatch(saveForm({...data, instance: this.props.instance.id})) }
                        />
                    )}

                    

                    {
                        this.props.history.location.pathname === '/productos' && (
                        <Grid container direction="row" justify="space-between" style={{marginLeft: 'auto', marginRight: 'auto',marginTop: 75, paddingTop: 30, flexWrap: 'wrap', maxWidth: 1200}}>

                            {this.props.products_categories && this.props.products_categories.length > 0 && this.props.products_categories.sort((a,b) => a.order - b.order).map((category) => (
                                <Grid key={category.title} xs={12} id={category.title} sm={12} item>
                                <h2 className="product-category">{category.title}</h2>
                                <Grid container wrap={"wrap"}>
                                    {this.props.products && this.props.products.filter(o => o.category.title == category.title).sort((a,b) => a.order - b.order).map(i => {
                                        return (
                                            <Grid key={i.title} onClick={()=> this.handleProduct(i)} id={`${i.category.title.replace(/ /g, '_')}-${i.title.replace(/ /g, '_')}`} align="center" item xs={12} sm={6} md={3}>
                                                <ProductCard {...i} />
                                            </Grid>
                                        )
                                    })}
                                </Grid> 
                                </Grid>
                            ))}
                        </Grid>
                    )
                }
                {this.props.history.location.pathname === '/sucursales' && (
                    <Grid container direction="row" justify="space-between" style={{marginLeft: 'auto', marginRight: 'auto',marginTop: 75, paddingTop: 30, flexWrap: 'wrap', maxWidth: 1200}}>
                        <Grid container wrap={"wrap"}>
                            {this.props.sucursales && this.props.sucursales.sort((a,b) => a.order - b.order).map(i => {
                                return (
                                    <Grid key={i.title} onClick={()=> this.handleSucursal(i)} id={`${i.title.replace(/ /g, '_')}`} align="center" item xs={12} sm={6} md={4}>
                                        <ProductCard {...i} />
                                    </Grid>
                                )
                            })}
                        </Grid> 
                    </Grid>)
                }
                {this.props.history.location.pathname === '/nuestras-promociones' && (
                    <Grid container direction="row" justify="space-between" style={{marginLeft: 'auto', marginRight: 'auto',marginTop: 75, paddingTop: 30, flexWrap: 'wrap', maxWidth: 1200}}>
                        <Grid container wrap={"wrap"}>
                            {this.props.promotions && this.props.promotions.sort((a,b) => a.order - b.order).map(i => {
                                return (
                                    <Grid key={i.title} onClick={()=> this.handlePromocion(i)} id={`${i.title.replace(/ /g, '_')}`} align="center" item xs={12} sm={6} md={4}>
                                        <ProductCard {...i} />
                                    </Grid>
                                )
                            })}
                        </Grid> 
                    </Grid>)
                }
                     
                </div>
                )} 
                />

            
                <Route exact path={`/sucursales/:name`} component={()=> <Detailsview sucursales instance_id={this.props.instance.id} /> } />
                <Route exact path={`/productos/:name`} component={()=> <Detailsview instance_id={this.props.instance.id} /> } />
                <Route exact path={`/nuestras-promociones/:name`} component={()=> <Detailsview promociones instance_id={this.props.instance.id} /> } />
                
            </div>    
        )
    }

}

const mapStateToProps = (state) => ({
    ...state
})

export default connect(mapStateToProps)(Home);


