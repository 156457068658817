import Slider from "react-slick";

import React from 'react';
import ArrowRight from '@material-ui/icons/ArrowRight'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import { IconButton } from "@material-ui/core";

const Group = (props) => {
    const sliderElement = React.useRef(null)
    var settings = {
        dots: true,
        arrows: true,
        infinite: true,
        autoplay: true,
        speed: 2000,
        adaptiveHeight: true,
        autoplaySpeed: 10,
        ...props.settings,
        slidesToShow: document.body.clientWidth < 600 ? 1 : (props.settings && props.settings.slidesToShow) ? props.settings.slidesToShow : 1,
        slidesToScroll: document.body.clientWidth < 600 ? 1 : (props.settings && props.settings.slidesToScroll) ? props.settings.slidesToScroll : 1,
      };
      const getLabel = (name) => {
        return name.replace('', '');
      }
    return(
        <a key={props.id} onClick={props.onClick} style={{...styles.groupBox, width: props.width || '100%'}} className={props.className}>
            <Slider ref={sliderElement} {...settings} className="">
                {props.images && props.images.map((item,key) => (
                    <a key={key} style={{ height: '100%', width: '100%' }} onClick={ props.clickRow ?() => props.clickRow(item): () =>{} }>
                        {
                            // <div style={{...styles.item, minHeight: props.height || 125, backgroundSize: props.images.length == 1 &&  props.fullwidth ? "cover" : props.type || 'contain', backgroundImage: `url(${item.src})`}} />
                        }
                        <img alt={getLabel(item.src)} style={{...styles.item, minHeight: props.height || 125, width: '100%', height: 'auto' }} src={item.src} />
                    </a>
                ))}
                {props.comments && props.comments.map((item, key) => (
                    <div key={key}>
                        <div style={{...styles.item, minHeight: props.height || '10vh', backgroundSize: props.type || 'contain'}} className="comments-mobile">
                            <h3>{item.text}</h3>
                            <p>{item.author}</p>
                        </div>
                    </div>
                ))}
            </Slider>                
        </a>
    )
}

const styles = {
    groupBox : {
        display: 'flex',
        flexDirection: 'column',
        // width: '100%',
    },
    item: {
        width: '100%',
        // backgroundColor: '#000',
        color: '#fefefe',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat'
    },
    arrow: {
        position : 'relative',
        top: '45%',
        width: 200,
        left: '5%',
        zIndex: 99999999999999999
    },
    arrowN: {
        position : 'relative',
        top: '42%',
        width: 200,
        left: '90%',
        zIndex: 9999999999999999999
    }
}

export default Group;
