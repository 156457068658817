import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Route  } from  'react-router';
import './style.css';
import ContentView from './contentView';

import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import Detailsview from './detailsView';

class Home extends Component {
    state = {
        effect: 'fadeIn',

    }
    refs = {

    }
    componentDidMount(){
        
    }

    handleRoute = (route, top = true) => {
        this.props.history.push(route)
        top && window.scrollTo(0,0)
    }

    onSelect = route => {
        // this.props.history.push(route)
        // window.scrollTo(0,0)
    }

    onLoad = () => {
        this.setState({ effect: 'fadeOut' })
        setTimeout(() => this.props.dispatch({ type: 'SET_STATUS', payload: 'LOADED' }), 450)
    }

    closeModal(promotion) {
        this.props.dispatch({ type: promotion ? 'SET_PROMOTION_MODAL' :'HANDLE_MODAL', payload: { open: false } })
    }

    handleAction = action => {
        switch (action) {
            case 'modal-contact':
                this.props.dispatch({ type: 'HANDLE_MODAL', payload: {type: action, open: true } })
            case 'modal-job':
                this.props.dispatch({ type: 'HANDLE_MODAL', payload: {type: action, open: true } })
            default:
                break;
        }
    }

    render(){
        if(this.props.instance && Object.keys(this.props.instance).length < 2) return null
        if(this.props.instance.maintenance && false) {
            return (
                <div className="maintenance">
                    <img className="" height="300" src="/maintenance.png" />
                    <img className="" height="120" src="/maintenance2.png" />
                    <p>¡ Sitio web en construcción !</p>
                    <img className="bottom" height="300" src="/banner.png" />
                </div>
            )
        } 
        return (
            <div className="container">
                {
                    <NavBar                        
                       navImage={(this.props.instance && this.props.instance.pictures && this.props.instance.pictures.length > 0) ? this.props.instance.pictures[0].src : null}
                       onClick={this.handleRoute.bind(this)} 
                       onSelect={(e) => this.onSelect(e)}
                       hide={this.props.nav.hide} 
                       instance={this.props.instance}
                       products={this.props.products}
                       page_content={this.props.page_content}
                       setRef={(r, ref) => this.refs[r] = ref}
                   />
                }
                <div className="animated fadeIn" style={{marginTop: 75, minHeight: '50vh'}}>
                    { 
                        Object.keys(this.props.page_content).map(key => <Route key={key} exact={(key !== 'productos' && key !== 'sucursales' && key !== 'nuestras-promociones')} path={`/${key === 'home' ? '' :  key}`} component={ ContentView }/>)
                    }
                </div>

                

                { this.props.instance &&
                <Footer
                    {...this.props}
                    redirect={this.handleRoute.bind(this)}
                    action={(action)=> this.handleAction(action)}
                />
                }
            </div>    
        )
    }
}

const hasSubRoute = (data, key) => {
    let item = data[key]
    let response = item.sections.filter(item => item.canOpen);
    return response.length> 0
}

const mapStateToProps = (state) => ({
    ...state,
    modal: state.modal,
    nav: state.nav,
})

export default connect(mapStateToProps)(Home);
