import firebase from '../../firebase';

const instanceDB = firebase.firestore().collection('instances')
const eventsDB = firebase.firestore().collection('events')
const contactsDB = firebase.firestore().collection('contacts')
const categoriesDB = firebase.firestore().collection('event-categories')
const promotionsDB = firebase.firestore().collection('promociones')
const galleryDB = firebase.firestore().collection('gallery')
const commentsDB = firebase.firestore().collection('comments')
const productsCategoryDB = firebase.firestore().collection('products-category')
const productsDB = firebase.firestore().collection('products')
const sucursalesDB = firebase.firestore().collection('sucursales')

const pageContentDB = firebase.firestore().collection('page-content')
const pageSectionsDB = firebase.firestore().collection('page-sections')

const getInstance = (id) => {
  return (dispatch, getState) => {
    instanceDB.doc(id).get()
    .then((response) => {
      let primaryColor = response.data().primaryColor || '#fefefe'
      let secondaryColor = response.data().secondaryColor || '#cecece'
      let primaryTextColor = response.data().primaryTextColor || '#333'
      let secondaryTextColor = response.data().primaryTextColor || '#000'
      let navbarBackground = response.data().navbarBackground || '#fff'
      let navbarColor = response.data().navbarColor || '#333'
      let footerBackground = response.data().footerBackground || '#fff'
      let footerColor = response.data().footerColor || '#333'
      document.documentElement.style.setProperty('--primary-color', primaryColor);
      document.documentElement.style.setProperty('--secondary-color', secondaryColor);
      document.documentElement.style.setProperty('--navbar-background', navbarBackground);
      document.documentElement.style.setProperty('--navbar-text-color', navbarColor);
      document.documentElement.style.setProperty('--footer-background', footerBackground);
      document.documentElement.style.setProperty('--footer-text-color', footerColor);
      document.documentElement.style.setProperty('--primary-text-color', primaryTextColor);
      document.documentElement.style.setProperty('--secondary-text-color', secondaryTextColor);
      dispatch({ type: 'SET_INSTANCE', payload: {...response.data(), id: response.id} });
      dispatch(getPromotions())
    })
    .catch(err => {
      dispatch({ type: 'SET_STATUS', payload: 'NOT_FORM' })
    })
  }
}

const getGallery = () => {
  return (dispatch, getState) => {
    galleryDB.get()
    .then((response) => {
      let payload = {
        withEvent: [],
        noEvent: []
      } 
      response.forEach(e => {
        let data = { ...e.data(), id: e.id };
        (data.event_id) ? payload.withEvent.push(data) : payload.noEvent.push(data)
      });
      dispatch({ type: 'SET_GALLERY', payload: payload });
    })
    .catch(err => {
      dispatch({ type: 'SET_STATUS', payload: 'NOT_FORM' })
    })
  }
}

const getEvents = () => {
  return (dispatch, getState) => {
    eventsDB.get()
    .then((response) => {
      let payload = [] 
      response.forEach(e => {
        payload.push({ ...e.data(), id: e.id })
      });
      dispatch({ type: 'SET_EVENTS', payload: payload });
    })
    .catch(err => {
      dispatch({ type: 'SET_STATUS', payload: 'NOT_FORM' })
    })
  }
}

const getEvent = (id) => {
  return (dispatch, getState) => {
    eventsDB.doc(id).get()
    .then((doc) => {
      dispatch({ type: 'SET_EVENT', payload: {...doc.data(), id: doc.id} });
    })
    .catch(err => {
      dispatch({ type: 'SET_STATUS', payload: 'NOT_FORM' })
    })
  }
}

const getCategories = () => {
  return (dispatch, getState) => {
    categoriesDB.get()
    .then((response) => {
      let payload = [] 
      response.forEach(e => {
        payload.push({ ...e.data(), id: e.id })
      });
      dispatch({ type: 'SET_EVENT_CATEGORIES', payload });
    })
    .catch(err => {
      dispatch({ type: 'SET_STATUS', payload: 'NOT_FORM' })
    })
  }
}

export const getPromotions = () => {
  return async (dispatch, getState) => {
    try {
      let payload = await getPromociones();
      dispatch({ type: 'SET_PROMOTIONS', payload });
    } catch (error) {
      dispatch({ type: 'SET_STATUS', payload: 'NOT_FORM' })
    }   
  }
}

export const getPromociones = async () => {
  try {
    let promotions = await promotionsDB.get();
    return promotions.docs.map(e => ({ ...e.data(), id: e.id }));
  } catch (error) {
    return error
  }
}


const getContent = (instance_id, sections, allproducts, productsCategory) => {
  return new Promise(async(resolve, reject) => {
    let page_content = {}
    let sucursales = await getSursales(instance_id);
    let products = allproducts.map((i)=> ({...i, category: productsCategory.filter(c => i.category == c.id)[0] }))
    let content = await pageContentDB.where("instance", "==", instance_id).get()      
    let arr = content.docs.map(i => ({ ...i.data(), id: i.id }))
    sections.forEach((s) => { 
      page_content[s.route] = {...s, sections: arr.filter((a) => (a.section === s.id)) } 
    });
    resolve({page_content, sucursales, products})
  })
}


const getPageContent = (instance_id, callback = () =>{}) => {
  return async (dispatch, getState) => {
    try {
      let categories = await pageSectionsDB.where("instance", "==", instance_id).get()
      let productsCategory = await getPoductCategories(instance_id)
      let products = await getPoducts(instance_id)
      let sections = categories.docs.map(i => ({...i.data(), id: i.id}))
      let content = await getContent(instance_id, sections, products, productsCategory);  
      dispatch({type: 'SET_ALL', payload: {...content, products_categories: productsCategory}})
      setTimeout(()=> {
        dispatch({type: 'SET_STATUS', payload: 'LOAD'});
      },200);
    } catch (error) {
      console.log(error);
    }
  }
}



const getPoductCategories = (instance_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = [];
      let snapshot = await productsCategoryDB.where("instance", "==", instance_id).get()
      snapshot.forEach(i => result.push({...i.data(), id: i.id}));
      resolve(result)
    } catch (error) {
      reject(error)
    }
  })
}
const getPoducts = (instance_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = [];
      let snapshot = await productsDB.where("instance", "==", instance_id).get()
      snapshot.forEach(i => result.push({...i.data(), id: i.id}));
      resolve(result)
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}
const getSursales = (instance_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = [];
      let snapshot = await sucursalesDB.where("instance", "==", instance_id).get()
      snapshot.forEach(i => result.push({...i.data(), id: i.id}));
      resolve(result)
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}
const getProduct = async (instance_id, productName, categoryID) => {
    try {
      let result = [];
      let snapshot = await productsDB.where("instance", "==", instance_id).where("title", "==", productName).where("category", "==", categoryID).get()
      snapshot.forEach(i => result.push({...i.data(), id: i.id}));
      let category = await productsCategoryDB.doc(result[0].category).get()
      let product = {
        ...result[0],
        category: category.data()
      }
      console.log(product)
      return product
      // dispatch({ type: "SET_PRODUCT", payload: product })
    } catch (error) {
      console.log(error)
    }
}
const getGustos = async () => {
    try {
      let snapshot = await firebase.firestore().collection('gustos').get()
      let result = snapshot.docs.map(i => ({...i.data(), id: i.id}));
      return result
    } catch (error) {
      console.log(error)
    }
}


const saveForm = (data) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'SENDING_FORM', payload: 'NEWSLETTER' });
    try {
      await contactsDB.add({
        ...data,
        created_at: new Date().getTime()
      })  
      dispatch({ type: 'SENDED_FORM', payload: 'NEWSLETTER' });
    } catch (error) {
      dispatch({ type: 'SET_STATUS', payload: 'NOT_FORM' })
    }
  }
}


export {
  getEvents,
  getEvent,
  getInstance,
  saveForm, 
  getGallery,
  getGustos,
  getCategories,
  getPoductCategories,
  getPageContent,
  getProduct, 
  getSursales
}