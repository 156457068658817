import ReactDOM from "react-dom";
import React, { Component, useState } from 'react';
import { Router, Route, Redirect, Switch } from  'react-router';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

import {store, history } from './redux/store';
import { useEffect } from "react";
import Landing from './Containers/Landing'
import Detailsview from './Containers/Landing/detailsView';
import { getInstance, getPageContent } from "./redux/Actions";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cecece" 
    },
    secondary: {
      main: "#cecece"
    }
  },
});

const App = (props) => {  
  useEffect(() => {
    getData();
  }, [])
  const getData = async ()=> {
    store.dispatch(getPageContent(props.instance_id))
    store.dispatch(getInstance(props.instance_id))
  }
  return (
    <Provider store={ store }>
      <MuiThemeProvider theme={theme}>
        <Router history={history}>
          <RouterApp instance_id={props.instance_id} />
        </Router>
      </MuiThemeProvider>
    </Provider>
  )
}

const RouterApp = (props) => {
  const [load, setLoad] = useState(false)
  const loading = useSelector(state => state.status !== 'INITIAL')
  const page_content = useSelector(state => state.page_content)
  return (
    <Switch>
      <Route path="/" component={!loading ? LoadingPage : Landing} />
    </Switch>
  )
}

const LoadingPage = (props) => {  
  const id = document.getElementById('instance_id').getAttribute('token');
  return(
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
      {
        <img height="200" src={`https://firebasestorage.googleapis.com/v0/b/webcreathor.appspot.com/o/instances%2F${id}%2Floading.png?alt=media`} />
      }
    </div>
  )
}

ReactDOM.render(<App instance_id={document.getElementById('instance_id') ? document.getElementById('instance_id').getAttribute('token') :""} />, document.getElementById("root"));