const initialState = {
  modal: {
    open: false, 
    type: 'default'
  },
  nav: {
    hide: false
  },
  comments: [],
  user: {},
  promotions: [],
  events: [],
  events_categories: [],
  promotion: {
    open: false,
  },
  products: [],
  product: [],
  sucursales: [],

  instance: {
    footer: '',
  },
  page_content: {},
  banners: [],
  galleries: [],
  event: null,
  status: 'INITIAL',
}

export default (state = initialState, action = {}) => {
  switch (action.type) {

    case "SET_STATUS":
    return {
        ...state,
        status : action.type
    };

    case "SET_PROMOTION_MODAL":
    return {
        ...state,
        promotion : {
          ...state.promotion,
          ...action.payload
        }
    };

    case "SET_PROMOTIONS":
    return {
        ...state,
        promotions : action.payload
    };

    case "SET_INSTANCE_ID":
    return {
        ...state,
        instance_id : action.payload
    };

    case "SET_PRODUCTS":
    return {
        ...state,
        products : action.payload
    };

    case "SET_INSTANCE":
    return {
        ...state,
        instance : action.payload
    };

    case "SET_SUCURSALES":
    return {
        ...state,
        sucursales : action.payload
    };

    case "SET_COMMENTS":
    return {
        ...state,
        comments : action.payload
    };

    case "SET_PAGE_CONTENT":
    return {
        ...state,
        page_content : action.payload,
    };

    case "SET_PAGE_BANNERS":
    return {
        ...state,
        page_banners : {
          ...state.page_banners,
          ...action.payload,
        }
    };

    case "SET_ALL":
    return {
        ...state,
        ...action.payload,
    };

    case "SET_BANNERS":
    return {
        ...state,
        banners : action.payload
    };

    case "SET_PRODUCTS_CATEGORIES":
    return {
        ...state,
        products_categories : action.payload
    };

    case "SET_GALLERY":
    return {
        ...state,
        galleries : action.payload
    };

    case "SET_EVENT_CATEGORIES":
    return {
        ...state,
        events_categories : action.payload
    };

    case "SET_EVENTS":
    return {
        ...state,
        events : action.payload
    };

    case "SET_EVENT":
    return {
        ...state,
        event : action.payload, 
        nav: {...state.nav, hide: true}
    };
    
    case "HANDLE_MODAL":
    return {
        ...state,
        modal : {...state.modal, ...action.payload}
    };
    case "SET_PRODUCT":
    return {
        ...state,
        product : action.payload
    };

    case "HANDLE_NAV":
    return {
        ...state,
        nav : {...state.nav, ...action.payload}
    };



    
    default:
      return state;
  }
}
